@import "minima";
.post-list li{
    border-bottom: 1px solid;
    padding-bottom: 20px;
}
.post-list li:last-child{
    margin-bottom:0px;
}
#tags{
    list-style: none;
    margin-left: 0px;
}
#tags li{
      display:inline-block;
}
#tags li:before{
	content: "\a0| ";
}
#tags li:first-child:before{
	content: "";
}


